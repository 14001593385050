<template>
    <div>
        <Header
            :type="cat.image2 ? 'large' : 'small'"
            :title="cat.name"
            :text="cat['text_' + lang]"
            :actions="[]"
            :image="cat.image2"
        />

        <div id="assortiment" name="assortiment" class="c w-xxl mt-xxl pt-xxl">
            <ProductsList
                :list="products"
                to="Product"
            />

            <InViewTrigger key="trigger" :margin="200" @intersected="loadMore" />
        </div>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import ProductsList from '@/components/Products.vue';
import { strings } from 'p5-helpers';
import kebabcase from 'lodash.kebabcase';
import InViewTrigger from '@/components/ui/InViewTrigger.vue';

export default {
    name: 'Products',
    props: {
        id: Number,
    },
    components: {
        Header,
        ProductsList,
        InViewTrigger,
    },
    data() {
        return {
            products: [],
            count: 30,
            start: 0,
            atBottom: false,
        };
    },
    computed: {
        lang() {
            return this.$i18n.locale;
        },

        data() {
            return this.$store.getters.data;
        },

        cat() {
            if (this.data.products_categories) {
                return this.data.products_categories.find((it) => it.id === this.id);
            }
            return false;
        },
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => vm.getData());
    },
    beforeRouteUpdate(to, from, next) {
        next();
        this.start = 0;
        this.atBottom = false;
        this.getData();
    },
    mounted() {

    },
    methods: {
        kebabcase,

        setMeta(r) {
            if (r) {
                const lang = this.lang;
                let schemas = [];
                if (this.$route.meta.article.schema) {
                    if (typeof this.$route.meta.article.schema === 'function') {
                        schemas = this.$route.meta.article.schema(Array.isArray(r) ? r : [], this);
                    } else {
                        schemas = this.$route.meta.article.schema;
                    }
                }
                this.$store.commit('setMeta', {
                    data: {
                        title: this.cat[`meta_title_${lang}`],
                        description: this.cat[`meta_description_${lang}`],
                        keywords: (Array.isArray(this.cat[`meta_keywords_${lang}`]) ? this.cat[`meta_keywords_${lang}`] : []).map((it) => it.name).join(', '),
                    },
                    schemas,
                });
            }
        },

        getData() {
            if (!window.PRERENDER_INJECTED) {
                let url = strings.replaceBrackets(this.$route.meta.article.endpoint, this.$route.params);
                url += `&start=${this.start}&count=${this.count}`;
                console.log(url);
                this.$store.dispatch('get', url).then(this.processData);
            }
        },

        processData(r) {
            if (this.start === 0) {
                this.products = r;
                this.setMeta(r);
            } else {
                this.products = this.products.concat(r);
            }
            if (r.length < this.count) {
                this.atBottom = true;
            }
        },

        loadMore() {
            if (this.products.length > 0 && !this.atBottom) {
                this.start += this.count;
                this.getData();
            }
        }
    },
};
</script>
